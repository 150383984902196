$background-color: #f6f8f9;
$text-color: #292e42;

$section-color: #fff;

$brand-color: #6b75fa;
$brand-text-color: white;

$box-shadow-color: #42467e1f;

// Font
$main-font: "Gotham Rounded", sans-serif;

// Spacing
$mobile-form-padding: 0 1.5rem;
$desktop-form-padding: 0 3rem;
