@use "global" as *;

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $background-color;
}

// Scrollbar bug fix on mobile
@media screen and (min-width: 960px) {
  html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
    margin-left: 0;
  }
}

#root {
  min-height: 100vh;
  touch-action: manipulation;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  margin-bottom: 2rem;
  max-height: 3rem;

  @include md {
    max-height: 5rem;
  }
}

.page {
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-content {
  width: 50%;
  min-width: 300px;
  max-width: 767px;
  background-color: $section-color;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 15px $box-shadow-color;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
