@use "assets/styles/global" as *;

$spinner-background: rgba(48, 60, 55, 0.15);
$spinner-color: rgba(48, 60, 55, 0.8);

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.spinner {
  position: absolute;
  left: calc(50% - 30px);
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: spin 0.6s infinite linear;
  -moz-animation: spin 0.6s infinite linear;
  -o-animation: spin 0.6s infinite linear;
  animation: spin 0.6s infinite linear;
  border-radius: 100%;

  border-left: 6px solid $spinner-background;
  border-right: 6px solid $spinner-background;
  border-bottom: 6px solid $spinner-background;
  border-top: 6px solid $spinner-color;
}
