@use "assets/styles/global" as *;

.button {
  font-family: $main-font;
  text-align: center;
  border: none;

  &:focus {
    outline: none;
  }
  &:hover:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.7;
  }

  // Sizes
  &.large-button {
    padding: 18px 24px;
    border-radius: 12px;
    font-size: 20px;
  }

  &.medium-button {
    padding: 15px 20px;
    border-radius: 10px;
    font-size: 18px;
  }

  &.small-button {
    padding: 12px 16px;
    border-radius: 6px;
    font-size: 12px;
  }

  // Themes
  &.default-button {
    background: $brand-color;
    color: $brand-text-color;
  }
}
