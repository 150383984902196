@use "assets/styles/global" as *;

.error-boundary {
  box-sizing: border-box;
  padding: 5rem 1rem;
  text-align: center;

  @include md {
    padding: 10% 2rem;
  }

  .error-boundary-title {
    font-size: 3rem;
  }

  .button {
    margin-top: 1rem;
  }
}
