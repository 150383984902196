@use "assets/styles/global" as *;

.summary {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .summary-label {
    font-weight: bold;
    font-style: italic;
  }

  .button {
    margin-top: 2rem;
    align-self: center;
  }
}
