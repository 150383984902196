@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/Gotham-Rounded/GothamRnd-Bold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/Gotham-Rounded/GothamRnd-Medium.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/Gotham-Rounded/GothamRnd-Book.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/Gotham-Rounded/GothamRnd-Light.otf") format("opentype");
  font-weight: 100;
}
